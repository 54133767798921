/**
 * Module dependencies.
 */

import { LessonListSectionFragment } from 'src/api/entities/lessons/sections/list/types';
import { Section, SectionProps } from './index';
import { fadeInLeftAnimation } from 'src/core/constants/motion';
import { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { textStyles } from 'src/styles/typography';
import arrowIcon from 'src/assets/svgs/24/arrow-right.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = SectionProps & LessonListSectionFragment;

const List = styled.ul`
  ${textStyles.paragraph1}

  --font-size: 20px;
  --line-height: 26px;

  background-color: var(--color-neutral0);
  border-radius: var(--border-radius);
  display: grid;
  font-size: var(--font-size);
  font-weight: 400;
  gap: 24px;
  line-height: var(--line-height);
  margin: auto var(--gutter-cards);
  padding: 40px 32px 32px;

  li {
    padding-inline-start: 32px;
    position: relative;
  }

  li::before {
    background-color: var(--color-primary);
    content: '';
    height: 20px;
    left: 0;
    mask: url('data:image/svg+xml,${encodeURIComponent(arrowIcon)}');
    position: absolute;
    top: 2px;
    width: 20px;
  }
`;

/**
 * Export `LessonListSection` component.
 */

export const LessonListSection = forwardRef<HTMLAnchorElement, Props>(({ items, ...rest }, ref) => {
  return (
    <Section ref={ref} {...rest}>
      <List>
        {items?.map((item, index) => (
          <motion.li key={index} {...fadeInLeftAnimation()}>
            {item}
          </motion.li>
        ))}
      </List>
    </Section>
  );
});

LessonListSection.displayName = 'LessonListSection';
