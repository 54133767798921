/**
 * Module dependencies.
 */

import { ControlProps } from './index';
import { IconButton } from 'src/components/core/buttons/icon-button';
import { getPlayIcon, handleTogglePlay } from './toggle-play';
import { materialThickLight } from './colors';
import clockBackwardIcon from 'src/assets/svgs/20/clock-backward.svg';
import clockForwardIcon from 'src/assets/svgs/20/clock-forward.svg';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${materialThickLight};
  border-radius: 32px;
  display: flex;
  gap: 16px;
  height: max-content;
  left: 50%;
  opacity: 0;
  padding: 8px 16px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: var(--transition-default);
  transition-property: opacity, visibility;
  visibility: hidden;
  width: max-content;

  *:hover > &,
  &[data-force-visible='true'] {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
`;

/**
 * `StyledIconButton` styled component.
 */

const StyledIconButton = styled(IconButton)`
  &[data-play-button='true'] {
    > span {
      transform: translateX(1px);
    }
  }
`;

/**
 * Export `Overlay` component.
 */

export const Overlay = ({ videoRef, videoState }: Pick<ControlProps, 'videoRef' | 'videoState'>) =>
  videoState.currentTime > 0 && (
    <Wrapper
      data-force-visible={videoState.currentTime >= videoState.duration}
      data-theme={'dark'}
      onClick={event => event.stopPropagation()}
    >
      <IconButton
        icon={clockBackwardIcon}
        onClick={() => {
          videoRef.current.currentTime -= 10;
        }}
        size={'small'}
        variant={'ghost'}
      />

      <StyledIconButton
        data-play-button={videoState.currentTime < videoState.duration && !videoState.isPlaying}
        icon={getPlayIcon(videoState)}
        onClick={() => handleTogglePlay(videoRef)}
        size={'small'}
        variant={'ghost'}
      />

      <IconButton
        icon={clockForwardIcon}
        onClick={() => {
          videoRef.current.currentTime += 10;
        }}
        size={'small'}
        variant={'ghost'}
      />
    </Wrapper>
  );
