/**
 * Module dependencies.
 */

import { MutableRefObject } from 'react';
import { VideoState } from 'src/components/core/video-player-custom/reducer';
import pauseIcon from 'src/assets/svgs/20/pause.svg';
import playIcon from 'src/assets/svgs/20/play.svg';
import replayIcon from 'src/assets/svgs/20/replay.svg';

/**
 * Export `handleTogglePlay` util.
 */

export const handleTogglePlay = (videoRef: MutableRefObject<HTMLVideoElement>) => {
  if (videoRef.current.paused || videoRef.current.ended) {
    videoRef.current.play();
  } else {
    videoRef.current.pause();
  }
};

/**
 * Export `getPlayIcon` util.
 */

export const getPlayIcon = (videoState: VideoState) => {
  if (videoState.isPlaying) {
    return pauseIcon;
  }

  if (videoState.currentTime >= 0 && videoState.currentTime >= videoState.duration) {
    return replayIcon;
  }

  return playIcon;
};
