/**
 * Module dependencies.
 */

import { CSSProperties, useMemo, useRef, useState } from 'react';
import { ControlProps } from './index';
import { materialThickLight } from './colors';
import { textStyles } from 'src/styles/typography';
import { transparentize } from 'src/styles/utils/colors';
import styled from 'styled-components';

/**
 * Constants.
 */

const seekerWidth = 104;
const watchedActiveColor = 'var(--color-primaryForLight40)';
const watchedBackgroundColor = 'transparent';
const bufferActiveColor = materialThickLight;
const bufferBackgroundColor = transparentize('neutral20', 0.2);

/**
 * `StyledProgress` styled component.
 */

const StyledProgress = styled.progress`
  appearance: none;
  background-color: var(--progress-background-color);
  border: none;
  border-radius: 2px;
  color: var(--progress-active-color);
  height: 4px;
  inset: 6px 0;
  position: absolute;
  width: 100%;

  ::-webkit-progress-value {
    background-color: var(--progress-active-color);
    border-radius: 2px;
  }

  ::-moz-progress-bar {
    background-color: var(--progress-active-color);
    border-radius: 2px;
  }

  ::-webkit-progress-bar {
    background-color: var(--progress-background-color);
    border-radius: 2px;
  }
`;

/**
 * `ProgressWrapper` styled component.
 */

const ProgressWrapper = styled.div`
  cursor: pointer;
  padding: 8px 0;
  position: relative;
`;

/**
 * `Time` styled component.
 */

const Time = styled.div`
  ${textStyles.paragraph3}

  padding: 0 8px;

  * {
    display: inline-block;
    text-align: center;
  }
`;

/**
 * `MonospaceWrapper` styled component.
 */

const MonospaceWrapper = styled.span`
  > span {
    width: 1ch;
  }
`;

/**
 * `Monospace` component.
 */

const Monospace = ({ children }: { children: string }) => (
  <MonospaceWrapper>
    {children.split('').map((char, index) => (
      <span key={index}>{char}</span>
    ))}
  </MonospaceWrapper>
);

/**
 * `Seeker` styled component.
 */

const Seeker = styled.div`
  ${textStyles.small}

  bottom: 16px;
  opacity: 0;
  position: absolute;
  text-align: center;
  transform: translateY(50%);
  transition: var(--transition-default);
  transition-property: opacity, transform;
  width: 104px;

  *:hover > & {
    opacity: 1;
    transform: translateY(0%);
  }
`;

/**
 * Export `Progress` component.
 */

export const Progress = (props: Pick<ControlProps, 'dispatch' | 'videoRef' | 'videoState'>) => {
  const { dispatch, videoRef, videoState } = props;
  const progressBarRef = useRef<HTMLDivElement>(null!);
  const [mousePos, setMousePos] = useState(0);
  const [totalWidth, setTotalWidth] = useState(1);
  const mouseTime = useMemo(() => {
    const time = videoState.duration * Math.max(0, mousePos / totalWidth);

    return `${String(Math.floor(time / 60)).padStart(2, '0')}:${String(Math.floor(time % 60)).padStart(2, '0')}`;
  }, [mousePos, totalWidth, videoState.duration]);

  return (
    <ProgressWrapper
      onMouseDown={event => {
        const rect = progressBarRef.current.getBoundingClientRect();
        const pos = (event.pageX - rect.left) / progressBarRef.current.offsetWidth;

        if (videoRef.current.duration) {
          videoRef.current.currentTime = pos * videoRef.current.duration;
        }

        dispatch({ payload: true, type: 'isSeeking' });
      }}
      onMouseMove={event => {
        const rect = progressBarRef.current.getBoundingClientRect();
        const absolutePos = event.pageX - rect.left;
        const totalWidth = progressBarRef.current.offsetWidth;
        setMousePos(absolutePos);
        setTotalWidth(totalWidth);

        if (videoState.isSeeking && videoRef.current.duration) {
          const pos = absolutePos / totalWidth;
          videoRef.current.currentTime = pos * videoRef.current.duration;
        }
      }}
      onMouseUp={() => dispatch({ payload: false, type: 'isSeeking' })}
      ref={progressBarRef}
    >
      <StyledProgress
        max={100}
        style={
          {
            '--progress-active-color': bufferActiveColor,
            '--progress-background-color': bufferBackgroundColor
          } as CSSProperties
        }
        value={(100 * videoState.bufferEnd) / (videoState.duration || 1)}
      />

      <StyledProgress
        max={100}
        style={
          {
            '--progress-active-color': watchedActiveColor,
            '--progress-background-color': watchedBackgroundColor
          } as CSSProperties
        }
        value={(100 * videoState.currentTime) / (videoState.duration || 1)}
      />

      <Seeker
        onMouseDown={event => event.stopPropagation()}
        onMouseUp={event => event.stopPropagation()}
        style={{
          left: Math.min(totalWidth - seekerWidth, Math.max(0, mousePos - seekerWidth / 2))
        }}
      >
        {mouseTime}
      </Seeker>
    </ProgressWrapper>
  );
};

/**
 * Export `Timestamps` component.
 */

export const Timestamps = ({ videoState }: Pick<ControlProps, 'videoState'>) => (
  <Time>
    <Monospace>{String(Math.floor(videoState.currentTime / 60)).padStart(2, '0')}</Monospace>

    <span>{':'}</span>

    <Monospace>{String(Math.floor(videoState.currentTime % 60)).padStart(2, '0')}</Monospace>

    <span style={{ padding: '0px 6px' }}>{'/'}</span>

    <Monospace>{String(Math.floor(videoState.duration / 60)).padStart(2, '0')}</Monospace>

    <span>{':'}</span>

    <Monospace>{String(Math.floor(videoState.duration % 60)).padStart(2, '0')}</Monospace>
  </Time>
);
