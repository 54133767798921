/**
 * Module dependencies
 */

import 'swiper/css';
import { AppPage } from 'src/api/entities/apps/types';
import { Button } from 'src/components/core/buttons/button';
import { Carousel } from './carousel';
import { Container } from 'src/components/core/layout/container';
import { Image } from 'src/components/core/image';
import { Link } from 'src/components/core/links';
import { RawHtml } from 'src/components/core/raw-html';
import { RichText } from 'src/components/rich-text';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { media } from 'src/styles/media';
import { textStyles } from 'src/styles/typography';
import { transparentize } from 'src/styles/utils/colors';
import chevronIcon from 'src/assets/svgs/24/chevron-down.svg';
import styled from 'styled-components';

/**
 * Export `AppDetailsSectionProps` type.
 */

export type AppDetailsSectionProps = AppPage & {
  backButtonLabel: string;
  backButtonUrl: string;
  ctaLabel: string;
};

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  --app-card-size: 144px;
  --return-action-size: 51px;
  --gallery-thumbnail-width: 64px;

  background-color: var(--color-white);
`;

/**
 * `HeaderImage` styled component.
 */

const HeaderImage = styled(Image).attrs({ fill: true })`
  background-color: var(--color-black);
  max-height: var(--hero-image-height);
  object-fit: cover;
`;

/**
 * `BackTextWrapper` styled component.
 */

const BackTextWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  height: var(--return-action-size);
  width: max-content;
`;

/**
 * `HeroWrapper` styled component.
 */

const HeroWrapper = styled.div`
  --hero-image-height: 456px;

  background-color: var(--color-black);
  height: var(--hero-image-height);
  width: 100%;
  z-index: 1;
`;

/**
 * `HeaderContainer` styled component.
 */

const HeaderContainer = styled(Container)`
  color: var(--color-white);
  margin-top: calc(-1 * (var(--return-action-size) + var(--app-card-size) / 2));
  position: relative;
`;

/**
 * `Avatar` styled component.
 */

const Avatar = styled(Image).attrs({
  height: 80,
  width: 80
})`
  object-fit: contain;

  ${media.max.ms`
    height: 72px;
    padding: 8px;
    width: 72px;
  `}
`;

/**
 * `AppCardWrapper` styled component.
 */

const AppCardWrapper = styled.div`
  align-items: center;
  backdrop-filter: blur(12px);
  background-color: ${transparentize('neutral95', 0.85)};
  border-radius: var(--border-radius);
  display: grid;
  height: auto;
  margin: 0 var(--gutter-cards);
  padding: 16px 8px;

  ${media.min.ms`
    column-gap: 32px;
    grid-template-columns: 80px 1fr auto;
    height: var(--app-card-size);
    padding: 0 32px;
  `}
`;

/**
 * `AppInfoWrapper` styled component.
 */

const AppInfoWrapper = styled.div`
  display: grid;
  gap: 4px;

  ${media.max.ms`
    padding: 4px 8px 24px;
  `}
`;

/**
 * `TitleWrapper` styled component.
 */

const TitleWrapper = styled(Text).attrs({
  as: 'h2',
  block: true,
  fontWeight: 500,
  variant: 'heading2'
})`
  margin-bottom: 80px;
`;

/**
 * `StyledRichText` styled component.
 */

const StyledRichText = styled(RichText)`
  ${textStyles.paragraph1}

  font-weight: 400;
`;

/**
 * Export `AppCenterDetailsSection` component.
 */

export function AppCenterDetailsSection(props: AppDetailsSectionProps) {
  const { author, backButtonLabel, backButtonUrl, content, ctaLabel, ctaUrl, gallery, headerAsset, lead, title } =
    props;

  const avatar = props.avatar ?? props.logo;

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeroWrapper>{!!headerAsset && <HeaderImage alt={headerAsset?.alt || ''} src={headerAsset?.url} />}</HeroWrapper>

      <ContentWrapper>
        <HeaderContainer>
          <BackTextWrapper>
            <Svg icon={chevronIcon} size={'16px'} style={{ transform: 'rotate(90deg)' }} />

            <Link data-underline-animated href={backButtonUrl}>
              <Text fontWeight={500}>{backButtonLabel}</Text>
            </Link>
          </BackTextWrapper>

          <AppCardWrapper data-theme={'dark'}>
            {avatar ? <Avatar alt={avatar.alt || ''} src={avatar.url} /> : <span />}

            <AppInfoWrapper>
              <Text as={'h1'} fontWeight={700} variant={'heading3'}>
                {title}
              </Text>

              <Text color={'neutral40'} variant={'paragraph2'}>
                {author}
              </Text>
            </AppInfoWrapper>

            <Button hasLinkIcon href={ctaUrl} variant={'ghost'}>
              {ctaLabel}
            </Button>
          </AppCardWrapper>
        </HeaderContainer>

        <Container size={'narrow'} style={{ paddingBottom: '144px', paddingTop: '120px' }}>
          <TitleWrapper>
            <RawHtml>{lead}</RawHtml>
          </TitleWrapper>

          {!!gallery?.length && <Carousel gallery={gallery} />}

          <StyledRichText>{content}</StyledRichText>
        </Container>
      </ContentWrapper>
    </div>
  );
}
