/**
 * Module dependencies.
 */

import { ControlProps } from './index';
import { IconButton } from 'src/components/core/buttons/icon-button';
import { Svg } from 'src/components/core/svg';
import { materialThickDark } from './colors';
import { transparentize } from 'src/styles/utils/colors';
import { useDetectOutsideClick } from 'src/hooks/use-detect-outside-click';
import { useRef, useState } from 'react';
import { useTranslate } from 'src/context/i18n';
import dotsVerticalIcon from 'src/assets/svgs/20/dots-vertical.svg';
import pipIcon from 'src/assets/svgs/20/pip.svg';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  position: relative;
`;

/**
 * `MenuWrapper` styled component.
 */

const MenuWrapper = styled.ul`
  background-color: ${materialThickDark};
  border-radius: var(--border-radius);
  bottom: calc(100% + 36px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 8px 0;
  position: absolute;
  right: 0;
  transition: var(--transition-default);
  transition-property: opacity, visibility;
  width: max-content;

  > li > a,
  > li > button {
    align-items: center;
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    display: grid;
    gap: 12px;
    grid-template-columns: 20px 1fr;
    padding: 8px 16px;
    transition: background-color var(--transition-default);
    width: 100%;

    :focus,
    :focus-within,
    :hover {
      background-color: ${transparentize('neutral60', 0.72)};
    }
  }

  [data-controls-visible='false'] &,
  &[aria-hidden='true'] {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
`;

/**
 * Export `OptionsMenu` component.
 */

export const OptionsMenu = ({
  dispatch,
  videoRef,
  videoState
}: Pick<ControlProps, 'dispatch' | 'videoRef' | 'videoState'>) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslate();

  useDetectOutsideClick(ref, () => setIsOpen(false));

  return (
    <Wrapper ref={ref}>
      <MenuWrapper aria-hidden={!isOpen}>
        {document?.pictureInPictureEnabled && (
          <li>
            <button
              onClick={() => {
                if (document.pictureInPictureElement !== null) {
                  document.exitPictureInPicture();
                  dispatch({ payload: false, type: 'pip' });

                  return;
                }

                videoRef.current.requestPictureInPicture();
                dispatch({ payload: true, type: 'pip' });
              }}
            >
              <Svg icon={pipIcon} size={'20px'} />

              {t(`actions.video.pip.${videoState.pip ? 'close' : 'open'}`)}
            </button>
          </li>
        )}
      </MenuWrapper>

      <IconButton
        aria-label={'options menu'}
        icon={dotsVerticalIcon}
        onClick={() => setIsOpen(isOpen => !isOpen)}
        size={'small'}
        variant={'ghost'}
      />
    </Wrapper>
  );
};
