/**
 * Module dependencies.
 */

import { NextSeo } from 'next-seo';
import { PageIndexSeoFields, SEOFields } from 'src/api/entities/seo/types';
import { absoluteUrlResolve } from 'src/core/utils/url';
import { useRouter } from 'next/router';
import { useSettings } from 'src/context/settings';
import Head from 'next/head';

/**
 * Constants.
 */

const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';

/**
 * `Props` type.
 */

type Props = Partial<SEOFields & PageIndexSeoFields & { isPreview?: boolean }>;

/**
 * Export `SEO` component.
 */

export const SEO = (props: Props) => {
  const {
    canonicalUrl,
    description,
    isPreview,
    keywords,
    languageAlternates,
    noFollow,
    noIndex,
    ogDescription,
    ogImage,
    ogTitle,
    title,
    twitterDescription,
    twitterTitle
  } = props;

  const { asPath } = useRouter();
  const url = absoluteUrlResolve(asPath.replace(/#.*/, '').replace(/\?.*/, ''));
  const { globalSettings } = useSettings();

  return (
    <>
      <NextSeo
        canonical={canonicalUrl}
        description={description}
        languageAlternates={languageAlternates}
        nofollow={!isProduction || noFollow}
        noindex={!isProduction || noIndex}
        openGraph={{
          ...(ogImage?.url && { images: [ogImage] }),
          description: ogDescription || description,
          siteName: 'Uphold',
          title: ogTitle || title,
          type: 'website',
          url
        }}
        title={title}
        titleTemplate={isPreview ? '[Preview] | %s' : globalSettings?.seo?.titleTemplate}
        {...(keywords && { additionalMetaTags: [{ content: keywords.join(', '), name: 'keywords' }] })}
      />

      {(twitterDescription || twitterTitle) && (
        <Head>
          {twitterTitle && <meta content={twitterTitle} name={'twitter:title'} />}
          {twitterDescription && <meta content={twitterDescription} name={'twitter:description'} />}

          <meta content={globalSettings?.seo?.twitterHandle} name={'twitter:site'} />
          <meta content={'summary'} name={'twitter:card'} />
        </Head>
      )}
    </>
  );
};
