/**
 * Module dependencies.
 */

import { Version as VersionType } from 'src/types/pages';
import { axiosNextInstance } from 'src/api/lib/next';
import { transparentize } from 'src/styles/utils/colors';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  background-color: ${transparentize('info40', 0.8)};
  bottom: 0;
  display: grid;
  grid-template-areas: '. version action';
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 16px;
  left: 0;
  padding: 4px;
  position: fixed;
  right: 0;
  user-select: none;
  z-index: var(--z-index-preview-layer);
`;

/**
 * `Version` styled component.
 */

const Version = styled.span`
  color: var(--color-white);
  font-size: 10px;
  grid-area: version;
  justify-self: center;
  line-height: 11px;
`;

/**
 * `Action` styled component.
 */

const Action = styled.button`
  background-color: var(--color-white);
  border: 0;
  border-radius: 4px;
  color: var(--color-black);
  cursor: pointer;
  font-size: 10px;
  grid-area: action;
  justify-self: end;
  line-height: 11px;
  padding: 2px 8px;
`;

/**
 * Export `Preview` component.
 */

export const Preview = ({ version }: { version: VersionType }) => {
  const router = useRouter();
  const handleClearPreview = useCallback(async () => {
    await axiosNextInstance.post('/preview/clear', {}).then(() => {
      router.reload();
    });
  }, [router]);

  return (
    <Wrapper>
      <Version>{`[Preview Mode] - version: ${[version]}`}</Version>

      <Action onClick={() => handleClearPreview()}>{'Cancel Preview'}</Action>
    </Wrapper>
  );
};
