/**
 * Module dependencies.
 */

import { actions, categories } from 'src/core/constants/analytics';
import { trackStructEvent } from '@snowplow/browser-tracker';

/**
 * Constants.
 */

const gtagId = process.env.NEXT_PUBLIC_GOOGLE_TAG_ID;
const appId = process.env.NEXT_PUBLIC_SNOWPLOW_APP_ID;
const collectorUrl = process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL;

/**
 * `Action` type.
 */

type Action = keyof typeof actions;

/**
 * `Category` type.
 */

type Category = keyof typeof categories;

/**
 * Export `sendGTMEvent` util.
 */

export function sendGTMEvent(action: Action, value?: string | number) {
  if (typeof window.gtag === 'function' && gtagId) {
    window.gtag('event', actions[action], { value });
  }
}

/**
 * Export `sendSnowplowEvent` util.
 */

export function sendSnowplowEvent(action: Action, { category, property }: { category: Category; property?: string }) {
  if (!appId || !collectorUrl) {
    return;
  }

  trackStructEvent({
    action: actions[action],
    category: categories[category],
    property
  });
}
