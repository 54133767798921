/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Link } from 'src/components/core/links';
import { RawHtml } from 'src/components/core/raw-html';
import { Text } from 'src/components/core/text';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { scrollAnimations } from 'src/styles/animations';
import { useViewportObserver } from 'src/hooks/use-viewport-observer';
import kebabCase from 'lodash/kebabCase';
import styled from 'styled-components';

/**
 * Export `SectionProps` type.
 */

export type SectionProps = Omit<ComponentPropsWithoutRef<'section'>, 'title'> & {
  title?: string;
};

/**
 * `SectionWrapper` styled component.
 */

const SectionWrapper = styled.section`
  display: grid;
  grid-gap: 24px;
  padding: 24px 0;

  ${media.min.ms`
    grid-gap: 48px;
    padding: 48px 0;
  `}
`;

const Anchor = styled(Link).attrs(({ id }) => ({ as: 'a', href: `#${id}`, tabIndex: -1 }))`
  ${headingStyles}

  scroll-margin: calc(var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 3) 0;
`;

/**
 * Export `Section` component.
 */

export const Section = forwardRef<HTMLAnchorElement, SectionProps>((props, ref) => {
  const { children, title, ...rest } = props;
  const [titleRef, intersectionPosition] = useViewportObserver<HTMLDivElement>({ rootMargin: '60px 0px 0px 0px' });

  return (
    <SectionWrapper {...rest}>
      {!!title?.length && (
        <Anchor id={kebabCase(title.replace(/<\/?[^>]+>/g, '') ?? title)} ref={ref}>
          <Text as={'h2'} fontWeight={400} ref={titleRef} variant={'heading4'}>
            <RawHtml data-scroll={scrollAnimations.blurFadeInVertical} data-scroll-position={intersectionPosition}>
              {title}
            </RawHtml>
          </Text>
        </Anchor>
      )}

      {children}
    </SectionWrapper>
  );
});

Section.displayName = 'Section';
