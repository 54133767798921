/**
 * Module dependencies.
 */

import { LessonRichTextSectionFragment } from 'src/api/entities/lessons/sections/rich-text/types';
import { RichText } from 'src/components/rich-text';
import { Section, SectionProps } from './index';
import { forwardRef } from 'react';
import { textStyles } from 'src/styles/typography';
import arrowIcon from 'src/assets/svgs/24/arrow-right.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = SectionProps & LessonRichTextSectionFragment;

const StyledRichText = styled(RichText)`
  ${textStyles.paragraph1}

  ul, ol {
    display: grid;
    gap: 16px;
    margin-top: 32px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  ul li {
    padding-inline-start: 32px;
    position: relative;
  }

  ul li::before {
    background-color: var(--color-primary);
    content: '';
    height: 20px;
    left: 0;
    mask: url('data:image/svg+xml,${encodeURIComponent(arrowIcon)}');
    position: absolute;
    top: 2px;
    width: 20px;
  }

  font-weight: 400;
`;

/**
 * Export `LessonRichTextSection` component.
 */

export const LessonRichTextSection = forwardRef<HTMLAnchorElement, Props>(({ content, ...rest }, ref) => {
  return (
    <Section ref={ref} {...rest}>
      <StyledRichText>{content}</StyledRichText>
    </Section>
  );
});

LessonRichTextSection.displayName = 'LessonRichTextSection';
