/**
 * Module dependencies.
 */

import { RefObject, useMemo, useState } from 'react';
import { useIsomorphicLayoutEffect, useMotionValueEvent, useScroll } from 'framer-motion';

/**
 * Export `useActiveAnchor` hook.
 */

export function useActiveAnchor(anchorRefs: RefObject<Array<HTMLElement | null>>, key: string): [number, boolean] {
  const [anchorStarts, setAnchorStarts] = useState<Array<number>>([]);
  const [activeAnchor, setActiveAnchor] = useState(-1);
  const isPastFirst = useMemo(() => activeAnchor >= 0, [activeAnchor]);

  useIsomorphicLayoutEffect(() => {
    setActiveAnchor(-1);
    setAnchorStarts(anchorRefs.current?.map(node => node?.offsetTop ?? 0) ?? []);
  }, [key]);

  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, 'change', latest => {
    setActiveAnchor(
      anchorStarts.filter(Boolean).findLastIndex(anchorStart => latest >= anchorStart - window.innerHeight / 3)
    );
  });

  return [activeAnchor, isPastFirst];
}
