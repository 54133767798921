/**
 * Module dependencies.
 */

import { Dispatch, MutableRefObject } from 'react';
import { IconButton } from 'src/components/core/buttons/icon-button';
import { InitialPlayButton } from './initial-play-button';
import { OptionsMenu } from './options-menu';
import { Overlay } from './overlay';
import { Progress, Timestamps } from './progress';
import { VideoAction, VideoState } from 'src/components/core/video-player-custom/reducer';
import { VolumeControl } from './volume';
import { getPlayIcon, handleTogglePlay } from './toggle-play';
import fullscreenEnterIcon from 'src/assets/svgs/20/fullscreen-enter.svg';
import fullscreenExitIcon from 'src/assets/svgs/20/fullscreen-exit.svg';
import styled from 'styled-components';

/**
 * Export `ControlProps` type.
 */

export type ControlProps = {
  dispatch: Dispatch<VideoAction>;
  videoContainerRef: MutableRefObject<HTMLDivElement>;
  videoRef: MutableRefObject<HTMLVideoElement>;
  videoState: VideoState;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  color: var(--color-neutral10);
  display: grid;
  inset: auto 0 0;
  padding: 12px;
  position: absolute;
  transition: var(--transition-default);
  transition-property: transform, visibility;

  [data-controls-visible='false'] & {
    pointer-events: none;
    transform: translateY(100%);
    visibility: hidden;
  }
`;

/**
 * `ControlsRow` styled component.
 */

const ControlsRow = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  height: 36px;
  justify-content: space-between;
`;

/**
 * `Underlay` styled component.
 */

const Underlay = styled.div`
  background: linear-gradient(180deg, rgba(9, 13, 20, 0) 0%, rgba(9, 13, 20, 0.3) 75.4%, #090d14 100%);
  inset: 0;
  position: absolute;
  transition: var(--transition-default);
  transition-property: opacity, visibility;

  [data-controls-visible='false'] & {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
`;

/**
 * Export `VideoControls` component.
 */

export const VideoControls = ({ dispatch, videoContainerRef, videoRef, videoState }: ControlProps) => {
  return (
    <>
      <Underlay onClick={() => handleTogglePlay(videoRef)}>
        <Overlay videoRef={videoRef} videoState={videoState} />
      </Underlay>

      <InitialPlayButton videoRef={videoRef} videoState={videoState} />

      <Wrapper>
        <Progress dispatch={dispatch} videoRef={videoRef} videoState={videoState} />

        <ControlsRow data-theme={'dark'}>
          <IconButton
            aria-label={'play or pause'}
            icon={getPlayIcon(videoState)}
            onClick={() => handleTogglePlay(videoRef)}
            size={'small'}
            variant={'ghost'}
          />

          <Timestamps videoState={videoState} />

          <span style={{ flex: 1 }} />

          <VolumeControl videoRef={videoRef} videoState={videoState} />

          {!!document?.fullscreenEnabled && (
            <IconButton
              aria-label={'toggle-fullscreen'}
              icon={videoState.fullscreen ? fullscreenExitIcon : fullscreenEnterIcon}
              onClick={() => {
                if (document.fullscreenElement !== null) {
                  document.exitFullscreen();
                  dispatch({ payload: false, type: 'fullscreen' });

                  return;
                }

                videoContainerRef.current.requestFullscreen();
                dispatch({ payload: true, type: 'fullscreen' });
              }}
              size={'small'}
              variant={'ghost'}
            />
          )}

          <OptionsMenu dispatch={dispatch} videoRef={videoRef} videoState={videoState} />
        </ControlsRow>
      </Wrapper>
    </>
  );
};
