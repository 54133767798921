/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { LessonCtaSectionFragment } from 'src/api/entities/lessons/sections/cta/types';
import { Section, SectionProps } from './index';
import { Text } from 'src/components/core/text';
import { media } from 'src/styles/media';
import { scrollAnimations } from 'src/styles/animations';
import { useViewportObserver } from 'src/hooks/use-viewport-observer';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = SectionProps & LessonCtaSectionFragment;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.max.ms`
    text-align: center;
  `}

  ${media.min.ms`
    flex-direction: row;
    gap: 16px;
    justify-content: center;
  `}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ variant: 'subtitle2' })`
  padding: 8px;

  ${media.min.ms`
    max-width: 504px;
  `}
`;

/**
 * Export `LessonCtaSection` component.
 */

export const LessonCtaSection = ({ cta, description, ...rest }: Props) => {
  const [ref, intersectionPosition] = useViewportObserver<HTMLDivElement>({ rootMargin: '120px 0px 0px 0px' });

  return (
    <Section {...rest}>
      <Wrapper ref={ref}>
        {!!description?.length && (
          <Description data-scroll={scrollAnimations.fadeInVertical} data-scroll-position={intersectionPosition}>
            {description}
          </Description>
        )}

        {cta?.href && !!cta?.label?.length && (
          <Button
            {...omit(cta, 'label')}
            aria-label={cta.label}
            data-scroll={scrollAnimations.fadeIn}
            data-scroll-delay={100}
            data-scroll-position={intersectionPosition}
            hasLinkIcon
            size={'large'}
            variant={'primary'}
          >
            {cta.label}
          </Button>
        )}
      </Wrapper>
    </Section>
  );
};
