/**
 * Export `actions` constant.
 */

export const actions = {
  courseCompleted: 'course_completed',
  lessonCompleted: 'lesson_completed'
} as const;

/**
 * Export `categories` constant.
 */

export const categories = {
  learning: 'Learning'
} as const;
