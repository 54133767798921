/**
 * Module dependencies.
 */

import { Button, ButtonProps } from 'src/components/core/buttons/button';
import { ComponentPropsWithoutRef } from 'react';
import { Container } from 'src/components/core/layout/container';
import { Media } from 'src/components/media';
import { Media as MediaType } from 'src/api/entities/media/types';
import { RawHtml } from 'src/components/core/raw-html';
import { Text } from 'src/components/core/text';
import { blurFadeInTopAnimation, fadeInAnimation } from 'src/core/constants/motion';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = Omit<ComponentPropsWithoutRef<'section'>, 'title'> & {
  asset?: MediaType;
  cta: ButtonProps & { label: string };
  description?: string;
  id: string;
  title?: string;
};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  background-color: var(--color-background);
  color: var(--color-text);
  overflow: hidden;
  position: relative;
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Container)`
  min-height: 100vh;
  min-height: 100svh;
  position: relative;
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageOverlay = styled.div`
  background: linear-gradient(
    180deg,
    rgba(9, 13, 20, 0) 0%,
    rgba(9, 13, 20, 0) 59.56%,
    rgba(9, 13, 20, 0.8) 70.45%,
    #090d14 100%
  );

  inset: 0;
  position: absolute;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  gap: 16px;
  inset: calc(
      var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 3
    )
    0 40px;
  padding-left: inherit;
  padding-right: inherit;
  position: absolute;

  > div:first-child {
    display: grid;
    gap: 16px;
  }

  ${media.max.ms`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}

  ${media.min.ms`
    bottom: 10vh;
    display: grid;
    justify-items: center;
    text-align: center;
    top: auto;

    > div:first-child {
      justify-items: center;
    }
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({ as: 'h1', variant: 'heading1' })`
  color: var(--color-primary);
  font-weight: 700;
  margin-bottom: 24px;
  max-width: 848px;

  ${headingStyles}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ as: 'h2', variant: 'subtitle1' })`
  font-weight: 400;
  margin-bottom: 24px;
  max-width: 760px;

  ${headingStyles}
`;

/**
 * `CtasWrapper` styled component.
 */

const CtaWrapper = styled(motion.div)`
  align-self: center;
  margin-top: 40px;
`;

const delay = 0.1;

/**
 * Export `CourseHeroSection` component.
 */

export function CourseHeroSection(props: Props) {
  const { asset, cta, description, id, title, ...rest } = props;
  const { label, ...ctaProps } = cta;

  return (
    <Section data-theme={'dark'} {...rest}>
      <Media imageProps={{ fill: true, priority: true }} key={id} media={asset} />

      <ImageOverlay />

      <Wrapper size={'default'}>
        <Grid>
          <div>
            <motion.div {...blurFadeInTopAnimation(delay)}>
              <RawHtml element={Title}>{title}</RawHtml>
            </motion.div>

            <motion.div {...blurFadeInTopAnimation(delay * 2)}>
              <RawHtml element={Description}>{description}</RawHtml>
            </motion.div>
          </div>

          <CtaWrapper {...fadeInAnimation(delay * 3)}>
            <Button {...ctaProps} aria-label={label} hasLinkIcon size={'large'}>
              {label}
            </Button>
          </CtaWrapper>
        </Grid>
      </Wrapper>
    </Section>
  );
}
