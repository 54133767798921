/**
 * Module dependencies.
 */

import { AnimatedWrapper } from 'src/components/core/card/animated';
import { CardWrapper } from 'src/components/core/card/shared';
import { Content } from 'src/components/core/card/content';
import { CoursePage } from 'src/api/entities/courses/types';
import { LessonCard, LessonCardProps } from 'src/components/core/card/lesson-card';
import { Media } from 'src/api/entities/media/types';
import { RouterLink } from 'src/components/core/links/router-link';
import { Section } from 'src/components/core/layout/section';
import { SubscriptionContentComponent } from 'src/components/sections/subscription';
import { SubscriptionSectionFragment } from 'src/api/entities/sections/subscription/types';
import { fadedCards } from 'src/components/sections/cards';
import { media } from 'src/styles/media';
import { useTranslate } from 'src/context/i18n';
import styled from 'styled-components';

/**
 * `WhatsNextProps` type.
 */

type WhatsNextProps = {
  className?: string;
  course?: Pick<CoursePage, 'description' | 'title' | 'id'> & {
    thumbnail?: Media;
    url: string;
  };
  lesson?: LessonCardProps;
  subscription?: SubscriptionSectionFragment;
  title: string;
};

/**
 * `CardsWrapper` styled component.
 */

const CardsWrapper = styled.div`
  --subscription-grid-padding: 32px;
  display: grid;
  gap: 8px;
  grid-auto-rows: 472px;
  margin: 0 var(--gutter-cards);

  > div {
    margin: 0 auto;
    width: min(456px, 100%);
  }

  ${media.min.ms`
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    margin-bottom: 52px;
    min-height: 620px;

    > div {
      width: unset;
      margin: 0;
    }

    &[data-small='true'] {
      min-height: 338px;
      margin-bottom: 0;
    }
  `}

  ${fadedCards};
`;

/**
 * `StyledAnimatedWrapper` styled component.
 */

const StyledAnimatedWrapper = styled(AnimatedWrapper)`
  border-radius: var(--border-radius);
  overflow: hidden;
`;

/**
 * Export `WhatsNextSection` component.
 */

export const WhatsNextSection = (props: WhatsNextProps) => {
  const { course, lesson, subscription, title, ...rest } = props;
  const { t } = useTranslate();

  return (
    <Section theme={'light'} title={title} {...rest}>
      <CardsWrapper data-small={!course && !lesson}>
        {(!!course || !!lesson) && (
          <>
            {course && !lesson && (
              <StyledAnimatedWrapper data-grid-card>
                <CardWrapper data-height={'full'}>
                  <Content
                    content={{
                      body: course.description,
                      media: course.thumbnail,
                      title: course.title
                    }}
                    cta={{
                      href: course.url,
                      label: t('course.startCourse')
                    }}
                    id={course.id}
                    layout={{
                      colSpan: '1.5',
                      height: 'full',
                      horizontalAlign: 'left',
                      verticalAlign: 'space-between'
                    }}
                  />
                </CardWrapper>
              </StyledAnimatedWrapper>
            )}

            {lesson && (
              <StyledAnimatedWrapper data-grid-card>
                <RouterLink href={lesson.slug}>
                  <LessonCard {...lesson} />
                </RouterLink>
              </StyledAnimatedWrapper>
            )}
          </>
        )}

        {subscription && (
          <SubscriptionContentComponent
            {...subscription}
            action={'whats_next_subscription'}
            data-grid-card
            layout={lesson || course ? 'card' : 'default'}
            rules={{
              required: t('subscription.form.email.rule.required'),
              validEmail: t('subscription.form.email.rule.validEmail')
            }}
          />
        )}
      </CardsWrapper>
    </Section>
  );
};
