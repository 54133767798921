/**
 * Module dependencies.
 */

import { RefObject, useEffect, useRef } from 'react';
import { useLocalStorage } from './use-local-storage';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import get from 'lodash/get';
import set from 'lodash/set';

/**
 * `Props` type.
 */

type Props<T> = {
  beforeSetValue?: (latest: number, progress: any, key: string, newValue: T) => void;
  delay?: number;
  key: string;
  nextKey?: string;
  parentRef: RefObject<HTMLElement>;
  rootKey: string;
};

/**
 * Export `useStoreScrollProgress` hook.
 */

export function useStoreScrollProgress<T extends object>({
  beforeSetValue,
  delay = 3000,
  key,
  nextKey,
  parentRef,
  rootKey
}: Props<T>) {
  const timeout = useRef<NodeJS.Timeout>();
  const [_store, setValue] = useLocalStorage<T>(rootKey);

  const { scrollYProgress } = useScroll({
    target: parentRef
  });

  useMotionValueEvent(scrollYProgress, 'change', latest => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      setValue((oldValue: T | null) => {
        const progress = get(oldValue, key);

        if (Array.isArray(progress) && progress[0] >= latest) {
          return oldValue;
        }

        const newValue = { ...oldValue } as T;
        set(newValue, key, [latest, Date.now()]);

        if (nextKey && !get(newValue, nextKey) && latest === 1) {
          const progress = get(oldValue, nextKey);

          set(newValue, nextKey, [progress?.[0] ?? 0, Date.now()]);
        }

        if (beforeSetValue) {
          beforeSetValue(latest, progress, key, newValue);
        }

        return newValue;
      });
    }, delay);
  });

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, [key]);
}
