/**
 * Module dependencies.
 */

import { useReducer } from 'react';

/**
 * Export `VideoState` type.
 */

export type VideoState = {
  bufferEnd: number;
  currentTime: number;
  duration: number;
  fullscreen: boolean;
  isOverlayVisible: boolean;
  isPlaying: boolean;
  isSeeking: boolean;
  muted: boolean;
  pip: boolean;
  volume: number;
};

/**
 * Export `VideoAction` type.
 */

export type VideoAction =
  | { payload: number; type: 'currentTime' }
  | { payload: number; type: 'duration' }
  | { payload: boolean; type: 'fullscreen' }
  | { payload: boolean; type: 'isOverlayVisible' }
  | { payload: boolean; type: 'isPlaying' }
  | { payload: boolean; type: 'isSeeking' }
  | { payload: boolean; type: 'muted' }
  | { payload: boolean; type: 'pip' }
  | { payload: number; type: 'volume' }
  | { payload: TimeRanges; type: 'buffer' };

/**
 * `initialVideoState` constant.
 */

const initialVideoState: VideoState = {
  bufferEnd: 0,
  currentTime: 0,
  duration: 0,
  fullscreen: false,
  isOverlayVisible: false,
  isPlaying: false,
  isSeeking: false,
  muted: false,
  pip: false,
  volume: 1
};

/**
 * Video reducer.
 */

const videoReducer = (state: VideoState, action: VideoAction): VideoState => {
  if (action.type === 'buffer') {
    let bufferEnd = 0;

    for (let index = 0; index < action.payload.length; index++) {
      const end = action.payload.end(index);

      if (end > state.currentTime) {
        bufferEnd = end;
        break;
      }
    }

    return { ...state, bufferEnd };
  }

  if (action.type in state) {
    return { ...state, [action.type]: action.payload };
  }

  return state;
};

/**
 * Export `useVideoState` component.
 */

export const useVideoState = () => useReducer(videoReducer, initialVideoState);
