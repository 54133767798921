/**
 * Module dependencies.
 */

import { AppCenterDetailsSection } from 'src/components/sections/app-center-details';
import { AppPage } from 'src/api/entities/apps/types';
import { useTranslate } from 'src/context/i18n';

/**
 * Export `AppCenterDetailsPage` component.
 */

export const AppCenterDetailsPage = (page: AppPage) => {
  const { t } = useTranslate();

  return (
    <AppCenterDetailsSection
      {...page}
      backButtonLabel={t('appCenterDetails.back')}
      backButtonUrl={page?.backUrl}
      ctaLabel={t('appCenterDetails.openWebsite')}
    />
  );
};
