/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Container } from 'src/components/core/layout/container';
import { Media } from 'src/components/media';
import { RawHtml } from 'src/components/core/raw-html';
import { RichTextClean } from 'src/components/rich-text/clean';
import { Subfooter as SubfooterSectionProps } from 'src/api/entities/sections/subfooter/types';
import { Text } from 'src/components/core/text';
import { blurFadeInTopAnimation, fadeInAnimation, fadeInTopAnimation } from 'src/core/constants/motion';
import { headingStyles } from 'src/components/core/layout/headings';
import { linkAnimatedStyles } from 'src/components/core/links';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * Export `Props` type.
 */

export type Props = NonNullable<SubfooterSectionProps>;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.section`
  background-color: var(--color-background);
  color: var(--color-text);
  padding: 32px 0;
  position: relative;
`;

/**
 * `Overlay` styled component.
 */

const Overlay = styled.div`
  background: linear-gradient(180deg, #090d1400 0%, #090d14b3 58%, var(--color-neutral105) 100%);
  inset: 0;
  position: absolute;
`;

/**
 * `Content` styled component.
 */

const Content = styled(Container)`
  align-items: center;
  display: grid;
  gap: 16px;
  grid-template-areas:
    'title'
    '.'
    'cta';
  grid-template-rows: max-content 1fr max-content;
  height: 360px;
  position: relative;

  ${media.min.ms`
    align-content: center;
    grid-template-areas:
      'title . cta';
    grid-template-columns: 480px 1fr min-content;
    grid-template-rows: auto max-content;
    height: 480px;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({ variant: 'heading3' })`
  ${headingStyles}

  grid-area: title;
`;

/**
 * `FinePrint` styled component.
 */

const FinePrint = styled(Text).attrs({ variant: 'small' })`
  ${headingStyles}

  a {
    ${linkAnimatedStyles}

    color: var(--color-primary);
  }

  position: relative;
  top: 16px;

  ${media.min.ms`
    position: absolute;
    inset: calc(100% + 12px) 16px auto;
    text-align: right;
  `}
`;

/**
 * Export `Subfooter` component.
 */

export const Subfooter = ({ cta, finePrint, media, title }: Props) => (
  <Wrapper data-theme={'dark'}>
    {media && (
      <>
        <Media
          imageProps={{
            fill: true,
            priority: true
          }}
          media={media}
        />

        <Overlay />
      </>
    )}

    <Content size={'narrow'}>
      {title && (
        <motion.div {...blurFadeInTopAnimation()}>
          <RawHtml element={Title}>{title}</RawHtml>
        </motion.div>
      )}

      <div style={{ gridArea: 'cta', position: 'relative' }}>
        {cta && (
          <motion.span {...fadeInAnimation()}>
            <Button {...omit(cta, 'label')} hasLinkIcon size={'large'}>
              {cta.label}
            </Button>
          </motion.span>
        )}

        {finePrint && (
          <motion.div {...fadeInTopAnimation(0.15)}>
            <RichTextClean element={FinePrint}>{finePrint}</RichTextClean>
          </motion.div>
        )}
      </div>
    </Content>
  </Wrapper>
);
