/**
 * Module dependencies
 */

import { CourseHeroSection } from './hero';
import { CoursePage as CoursePageType } from 'src/api/entities/courses/types';
import { DisclaimerSection } from 'src/components/sections/disclaimer';
import { LESSON_PROGRESS_KEY, LessonProgress } from 'src/types/local-storage';
import { LessonCard } from 'src/components/core/card/lesson-card';
import { RouterLink } from 'src/components/core/links/router-link';
import { Section } from 'src/components/core/layout/section';
import { WhatsNextSection } from 'src/components/sections/whats-next';
import { fadedCards } from 'src/components/sections/cards';
import { media } from 'src/styles/media';
import { useLocalStorage } from 'src/hooks/use-local-storage';
import { useMemo } from 'react';
import { useTranslate } from 'src/context/i18n';
import styled from 'styled-components';

/**
 * `LessonsWrapper` styled component.
 */

const LessonsWrapper = styled.div`
  --lesson-card-height: 520px;

  display: grid;
  gap: 8px;
  grid-auto-rows: var(--lesson-card-height);
  grid-template-columns: 1fr;
  margin: 0 var(--gutter-cards);
  padding-top: 88px;

  ${media.min.ms`
    padding-top: 56px;
    grid-template-columns: 1fr 1fr ;
  `}

  ${media.min.md`
    grid-template-columns: 1fr 1fr 1fr;
  `}

  ${fadedCards}
`;

/**
 * `StyledLessonCard` styled component.
 */

const StyledLessonCard = styled(LessonCard)`
  ${media.max.ms`
    width: min(456px, 100%);
    margin: 0 auto;
  `}
`;

/**
 * `StyledDisclaimerSection` styled component.
 */

const StyledDisclaimerSection = styled(DisclaimerSection)`
  padding: 0;
`;

/**
 * Export `CoursePage` component.
 */

export function CoursePage(props: CoursePageType) {
  const { asset, courses, description, disclaimer, id, lessons, subscription, title, whatsNextTitle } = props;
  const { t } = useTranslate();
  const [store] = useLocalStorage<LessonProgress>(LESSON_PROGRESS_KEY);
  const courseIndex = useMemo(() => courses.findIndex(course => course.id === id), [courses, id]);
  const lessonIndex = useMemo(
    () =>
      lessons.findIndex(lesson => {
        const progress = store?.[id]?.[lesson.id]?.[0];

        return !progress || progress < 1;
      }),
    [lessons, store, id]
  );

  return (
    <>
      <CourseHeroSection
        asset={asset}
        cta={
          lessonIndex === -1 || lessonIndex === 0
            ? {
                href: lessons[0].url,
                label: t('course.startCourse')
              }
            : {
                href: lessons[lessonIndex].url,
                label: t('course.continueCourse')
              }
        }
        description={description}
        id={id}
        title={title}
      />

      <Section>
        <LessonsWrapper>
          {lessons.map((lesson, index) => (
            <RouterLink href={lesson.url} key={index}>
              <StyledLessonCard
                asset={lesson.asset}
                data-grid-card
                duration={lesson.duration}
                index={index + 1}
                key={index}
                progress={store?.[props.id]?.[lesson.id]?.[0]}
                subtitle={lesson.description ?? ''}
                thumbnail={lesson.thumbnail}
                title={lesson.title}
              />
            </RouterLink>
          ))}
        </LessonsWrapper>
      </Section>

      {!!disclaimer && <StyledDisclaimerSection {...disclaimer} />}

      <WhatsNextSection
        {...(courseIndex !== -1 && { course: courses[courseIndex + 1] })}
        subscription={subscription}
        title={whatsNextTitle}
      />
    </>
  );
}
