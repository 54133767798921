/**
 * Module dependencies.
 */

import { AppCenterDetailsPage } from 'src/components/pages/app-center-details';
import { CoursePage } from 'src/components/pages/course/index';
import { DefaultPage } from 'src/components/pages/default';
import { GetStaticPaths, GetStaticProps, GetStaticPropsResult, NextPage } from 'next';
import { LessonPage } from 'src/components/pages/lesson';
import { PageProps } from 'src/types/app';
import { Preview } from 'src/components/preview';
import { SEO } from 'src/components/core/seo';
import { Settings } from 'src/api/entities/settings/types';
import { Subfooter } from 'src/components/layout/footer/subfooter';
import { fetchParentPageSlug } from 'src/api/entities/pages/parent-page-slug/queries';
import { fetchSettings } from 'src/api/entities/settings/queries';
import { fsCache } from 'src/core/utils/fs-cache';
import { getAllPageSlugs, getAllPageSlugsFlat, getPageSlugsByHumanId } from 'src/api/entities/pages/slug/queries';
import { getAncestorData } from 'src/api/content-data/ancestors';
import { getCtxLocale, getLocale, getSlugLocale } from 'src/core/utils/locale';
import { getPagesContentData } from 'src/api/content-data/pages';
import { getPreviewMode } from 'src/core/utils/preview';
import { getUnlocalizedSlug } from 'src/core/utils/pages';
import { logger } from 'src/core/utils/debug';
import { pageStatus } from 'src/core/constants/pages';
import { regionLanguages } from 'src/core/constants/i18n';
import { replaceServerVariables } from 'src/core/utils/server-variables';
import { serverContext } from 'src/core/utils/server-context';

/**
 * Constants.
 */

const revalidate = Number(process.env.NEXT_REVALIDATE_INTERVAL);

/**
 * `DynamicPage`.
 */

const DynamicPage: NextPage<PageProps> = props => {
  const { isPreview, page, seo, type, version } = props;

  const renderPageContent = () => {
    switch (type) {
      case 'appDetailsPage':
        return <AppCenterDetailsPage {...page} />;

      case 'coursePage':
        return <CoursePage {...page} />;

      case 'lessonPage':
        return <LessonPage {...page} />;

      default:
        return <DefaultPage {...page} />;
    }
  };

  return (
    <>
      <SEO {...seo} {...page?.metadata} isPreview={isPreview} />

      {renderPageContent()}

      {page?.subfooter && <Subfooter {...page.subfooter} />}

      {isPreview && <Preview version={version} />}
    </>
  );
};

/**
 * Export `getStaticPaths`.
 */

export const getStaticPaths: GetStaticPaths = async () => {
  const { slugs } = await getAllPageSlugs({ status: pageStatus });
  const validSlugs = slugs.filter(slug => {
    const { languageCode, region } = getSlugLocale(slug);

    return !!regionLanguages[region]?.includes(languageCode);
  });

  return {
    fallback: 'blocking',
    paths: validSlugs.map(slug => ({ params: { slug } }))
  };
};

/**
 * Export `getStaticProps`.
 */

export const getStaticProps: GetStaticProps<PageProps> = async ctx => {
  const slug = ctx?.params?.slug as string[];
  const { languageCode, region: regionCode } = getCtxLocale(slug);
  const unlocalizedSlug = getUnlocalizedSlug(slug);
  const parentSlug = await fetchParentPageSlug({
    languageCode,
    regionCode,
    slug: !unlocalizedSlug?.length ? '/' : `/${unlocalizedSlug.join('/')}`,
    status: pageStatus
  });

  const store = {
    languageCode,
    locale: getLocale(regionCode, languageCode),
    parentSlug,
    regionCode,
    slugsByHumanPageId: await fsCache('slugs-by-page-id', () => getPageSlugsByHumanId({ status: pageStatus }))
  };

  const slugs = await fsCache(`slugs-flat`, () => getAllPageSlugsFlat({ status: pageStatus }));

  return await serverContext.run(store, async () => {
    const previewMode = getPreviewMode(ctx);
    const version = previewMode?.data?.version;
    const settings = (await fsCache(`settings-${store.locale}${version ? `-${version}` : ''}`, () => {
      return fetchSettings({ languageCode, regionCode, version });
    })) as Settings;

    try {
      const pageData = await getPagesContentData({
        languageCode,
        regionCode,
        slug: slug ?? [],
        status: pageStatus,
        version
      });

      const { variables, ...ancestorsData } = await getAncestorData(pageData, {
        languageCode,
        pagesTreeView: settings?.regionSettings?.pagesTreeView ?? [],
        regionCode,
        status: pageStatus
      });

      return {
        props: replaceServerVariables(
          {
            ...pageData,
            ...ancestorsData,
            ...(pageData?.page?.navbar && { navbar: pageData.page.navbar }),
            ...(pageData?.page?.footer && { footer: pageData.page.footer }),
            isPreview: previewMode.isPreview,
            locale: `${languageCode}-${regionCode}`,
            settings,
            slugs,
            version
          },
          variables
        ),
        revalidate
      } satisfies GetStaticPropsResult<PageProps>;
    } catch (error) {
      logger.error({ error, languageCode, regionCode, slug }, 'Page not found');

      return {
        notFound: true,
        revalidate
      };
    }
  });
};

/**
 * Export `DynamicPage`.
 */

export default DynamicPage;
