/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, useEffect, useRef, useState } from 'react';
import { VideoControls } from './controls';
import { VideoJsonLd, VideoJsonLdProps } from 'next-seo';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useLocalStorage } from 'src/hooks/use-local-storage';
import { useVideoState } from './reducer';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = ComponentPropsWithoutRef<'video'> & {
  onSaveProgress?: (progress: number) => void;
  seoJsonData?: VideoJsonLdProps;
};

/**
 * `Video` styled component.
 */

const Video = styled.video`
  transition: aspect-ratio var(--transition-default);
  width: 100%;
`;

/**
 * `VideoContainer` styled component.
 */

const VideoContainer = styled.div`
  align-items: center;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

/**
 * Export `VideoPlayerCustom` component.
 */

export const VideoPlayerCustom = ({ onSaveProgress, seoJsonData, ...props }: Props) => {
  const [aspectRatio, setAspectRatio] = useState('16 / 9');
  const [supportsVideo, setSupportsVideo] = useState(false);
  const [videoState, dispatch] = useVideoState();
  const videoContainerRef = useRef<HTMLDivElement>(null!);
  const videoRef = useRef<HTMLVideoElement>(null!);
  const [isVideoHovered, setIsVideoHovered] = useState(false);
  const controlsTimeoutRef = useRef<NodeJS.Timeout>();
  const [volumeState, setVolume] = useLocalStorage<[number, boolean]>('media-volume');
  const [volumeSynced, setVolumeSynced] = useState(false);
  const isMobile = !useBreakpoint('ms');

  useEffect(() => {
    if (supportsVideo) {
      return;
    }

    const supportsVideoCheck = !!videoRef.current?.canPlayType;
    setSupportsVideo(supportsVideoCheck);

    if (supportsVideoCheck) {
      videoRef.current.controls = false;
    }
  }, [supportsVideo]);

  useEffect(() => {
    const onFullscreenChange = async () => {
      const isFullscreen = !!document.fullscreenElement;
      dispatch({ payload: isFullscreen, type: 'fullscreen' });

      try {
        if (isFullscreen && 'lock' in screen.orientation) {
          await (screen.orientation.lock as any)('landscape');
        }

        if (!isFullscreen && 'unlock' in screen.orientation) {
          await (screen.orientation.unlock as any)();
        }
      } catch {
        // Not supported for all browsers - do nothing if it fails
      }
    };

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, [dispatch]);

  useEffect(() => {
    const onPipChange = () => {
      dispatch({ payload: !!document.pictureInPictureElement, type: 'pip' });
    };

    document.addEventListener('enterpictureinpicture', onPipChange);
    document.addEventListener('leavepictureinpicture', onPipChange);

    return () => {
      document.removeEventListener('enterpictureinpicture', onPipChange);
      document.removeEventListener('leavepictureinpicture', onPipChange);
    };
  }, [dispatch]);

  useEffect(() => {
    if (!videoState.duration && videoRef.current.duration) {
      dispatch({ payload: videoRef.current.duration, type: 'duration' });
    }
  }, [dispatch, videoState.duration]);

  useEffect(() => {
    if (volumeSynced || !volumeState) {
      return;
    }

    videoRef.current.volume = volumeState[0];
    videoRef.current.muted = volumeState[1];
    setVolumeSynced(true);
  }, [volumeState, volumeSynced]);

  return (
    <>
      <VideoContainer
        data-controls-visible={!videoState.isPlaying || isVideoHovered}
        onMouseEnter={() => setIsVideoHovered(true)}
        onMouseLeave={() => setIsVideoHovered(false)}
        onMouseMove={() => {
          setIsVideoHovered(true);

          if (controlsTimeoutRef.current) {
            clearTimeout(controlsTimeoutRef.current);
          }

          controlsTimeoutRef.current = setTimeout(() => setIsVideoHovered(false), 2000);
        }}
        ref={videoContainerRef}
      >
        <Video
          controls
          preload={'metadata'}
          {...props}
          onEnded={() => dispatch({ payload: false, type: 'isPlaying' })}
          onLoadedMetadata={() => dispatch({ payload: videoRef.current.duration, type: 'duration' })}
          onPause={() => dispatch({ payload: false, type: 'isPlaying' })}
          onPlay={() => {
            if (isMobile) {
              const videoWidth = videoRef.current.videoWidth;
              const videoHeight = videoRef.current.videoHeight;

              if (videoWidth - videoHeight < 0) {
                setAspectRatio('9 / 16');
              }
            }

            dispatch({ payload: true, type: 'isPlaying' });
          }}
          onProgress={() => dispatch({ payload: videoRef.current.buffered, type: 'buffer' })}
          onTimeUpdate={() => {
            dispatch({ payload: videoRef.current.currentTime, type: 'currentTime' });
            dispatch({ payload: videoRef.current.buffered, type: 'buffer' });

            if (!videoState.duration && videoRef.current.duration) {
              dispatch({ payload: videoRef.current.duration, type: 'duration' });
            }

            onSaveProgress?.(videoRef.current.currentTime / videoRef.current.duration);
          }}
          onVolumeChange={() => {
            dispatch({ payload: videoRef.current.volume, type: 'volume' });
            dispatch({ payload: videoRef.current.muted, type: 'muted' });
            setVolume([videoRef.current.volume, videoRef.current.muted]);
          }}
          ref={videoRef}
          style={{ aspectRatio }}
        />

        {supportsVideo && (
          <VideoControls
            dispatch={dispatch}
            videoContainerRef={videoContainerRef}
            videoRef={videoRef}
            videoState={videoState}
          />
        )}
      </VideoContainer>

      {!isEmpty(seoJsonData) && <VideoJsonLd {...seoJsonData} />}
    </>
  );
};
