/**
 * Module dependencies.
 */

import { LearnCenterSectionFragment } from 'src/api/entities/sections/learn-center/types';
import { Page } from 'src/api/entities/pages/page/types';
import { Section } from 'src/components/sections';
import { useQueryParamsCapture } from 'src/hooks/use-query-params-capture';

/**
 * Export `DefaultPage` component.
 */

export const DefaultPage = (page: Page) => {
  useQueryParamsCapture(page);

  return page?.sections?.map(section => {
    if (section.sectionType === 'lessons_continue_section') {
      const learnCenter = page?.sections?.find(section => section.sectionType === 'learn_center_section')
        ?.section as LearnCenterSectionFragment;

      return <Section key={section.id} {...section} courses={learnCenter.courses} />;
    }

    return <Section key={section.id} {...section} />;
  });
};
